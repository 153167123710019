import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { Core, Toaster } from '@acin/ui-core';
import { Inspector, InspectorProvider } from '@acin/inspector';
import { RenderContentConfig } from '@acin/inspector/src/components/Inspector/components/InspectorContent';
import { ControlInsight } from '@acin/acin-terminal-risks-and-controls';
import { DownloadProvider, Download } from '@acin/download';
import createAuth0Client from '@auth0/auth0-spa-js';

// You can use this mock create auth0 client below, to skip communucations with Auth0,
// It will log the user in using the 'REACT_APP_MOCK_AUTH_TOKEN' value in the .env file.
// import { createAuth0Client } from './testHelpers/MockAuth0Client';

import { AuthProvider } from '@acin/user-accounts';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { getClient } from './config/apollo';

import { config, ORIGIN_URL_KEY } from './config/auth0';
import { onAuthenticated, onLogout } from './Auth';
import { datadogInit } from './datadog';

const renderContent = (config: RenderContentConfig) => {
    const { typename, data, inspect } = config;
    if (typename === 'ControlInspectorView' && data.content && inspect) {
        return {
            title: 'Control Insight',
            content: <ControlInsight id={inspect.id} data={data.content} />,
        };
    }

    return {
        title: 'Error',
        content: <>Unsupported type</>,
    };
};
createAuth0Client(config).then((auth0) => {
    const renderApp = async () => {
        datadogInit();
        const client = await getClient(auth0);

        ReactDOM.render(
            <ApolloProvider client={client}>
                <Core>
                    <AuthProvider
                        originUrlKey={ORIGIN_URL_KEY}
                        authClient={auth0}
                        onAuthenticated={onAuthenticated}
                        onLogout={onLogout}
                    >
                        <InspectorProvider>
                            <DownloadProvider>
                                <Inspector renderContent={renderContent} />
                                <Toaster />
                                <Download />
                                <App />
                            </DownloadProvider>
                        </InspectorProvider>
                    </AuthProvider>
                </Core>
            </ApolloProvider>,
            document.getElementById('root'),
        );
    };

    renderApp();
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
