type AssetType =
    | 'CLIENT_CONTROL'
    | 'ACIN_CONTROL'
    | 'CLIENT_RISK'
    | 'ACIN_RISK'
    | 'REG_ALERT'
    | 'NETWORK_UPDATE'
    | 'NETWORK_SCENARIO'
    | 'REPORT'
    | 'REPORT_BUILDER';

export const onNavigate = (id: string, type: AssetType) => {
    if (['CLIENT_CONTROL', 'ACIN_CONTROL'].includes(type)) {
        window.open(`/calibrate/control/${id}`, '_blank');
    }
    if (['CLIENT_RISK', 'ACIN_RISK'].includes(type)) {
        window.open(`/calibrate/risk/${id}`, '_blank');
    }
    if (['NETWORK_UPDATE', 'NETWORK_SCENARIO', 'REG_ALERT'].includes(type)) {
        window.open(`/risk-intelligence/${id}`, '_blank');
    }

    if (['REPORT'].includes(type)) {
        window.open(`/calibrate/report/${id}`, '_blank');
    }

    if (['REPORT_BUILDER'].includes(type)) {
        window.open(`/calibrate/report-builder/${id}`, '_blank');
    }
};
