import React from 'react';
import { Loading, LayoutHorizontal } from '@acin/ui-core';
import { Switch, Route } from 'react-router-dom';

import { getPathById } from '../components/MainNav/config/horizontalMenu';
import { MainNav } from '../components/MainNav';
import ScrollToTop from './ScrollToTop';

// Lazy load the pages
const Settings = React.lazy(() => import('../pages/Settings').then((module) => ({ default: module.Settings })));
// const Dashboard = React.lazy(() => import('../pages/Dashboard').then((module) => ({ default: module.Dashboard })));
const InterimDashboard = React.lazy(() =>
    import('../pages/InterimDashboard').then((module) => ({ default: module.InterimDashboard })),
);
const Search = React.lazy(() => import('../pages/Search').then((module) => ({ default: module.Search })));
const RiskIntelligence = React.lazy(() =>
    import('../pages/RiskIntelligence/components/RiskIntelligence').then((module) => ({
        default: module.RiskIntelligence,
    })),
);

const RiskIntelligenceAdmin = React.lazy(() =>
    import('../pages/RiskIntelligence/components/RiskIntelligenceAdmin').then((module) => ({
        default: module.RiskIntelligenceAdmin,
    })),
);

const RiskIntelligenceHub = React.lazy(() =>
    import('../pages/RiskIntelligence/components/RiskIntelligenceHub').then((module) => ({
        default: module.RiskIntelligenceHub,
    })),
);

const ConnectData = React.lazy(() =>
    import('../pages/ConnectData').then((module) => ({ default: module.ConnectData })),
);
const ManageAccounts = React.lazy(() =>
    import('../pages/ManageAccounts').then((module) => ({ default: module.ManageAccounts })),
);
const Explorer = React.lazy(() => import('../pages/Explorer').then((module) => ({ default: module.Explorer })));
const Calibrate = React.lazy(() => import('../pages/Calibrate').then((module) => ({ default: module.Calibrate })));
const Documents = React.lazy(() => import('../pages/Documents').then((module) => ({ default: module.Documents })));
const Notifications = React.lazy(() =>
    import('../pages/Notifications').then((module) => ({ default: module.Notifications })),
);
const Upload = React.lazy(() => import('../pages/Upload').then((module) => ({ default: module.Upload })));
const DataQuality = React.lazy(() =>
    import('../pages/DataQuality').then((module) => ({ default: module.DataQuality })),
);

export const PrivateLayout = () => (
    <LayoutHorizontal
        renderNavigation={() => <MainNav />}
        renderContent={() => (
            <div>
                <React.Suspense fallback={<Loading position="absolute" withOverlay />}>
                    <ScrollToTop />
                    <Switch>
                        <Route exact path={getPathById('search')}>
                            <Search />
                        </Route>
                        <Route exact path={getPathById('dashboard')}>
                            <InterimDashboard />
                        </Route>
                        <Route path={getPathById('riskIntelligenceAdmin')}>
                            <RiskIntelligenceAdmin />
                        </Route>
                        <Route exact path={getPathById('riskIntelligenceHub')}>
                            <RiskIntelligenceHub />
                        </Route>
                        <Route path={getPathById('riskIntelligence')}>
                            <RiskIntelligence />
                        </Route>
                        <Route path={getPathById('explorer')}>
                            <Explorer />
                        </Route>
                        <Route path={getPathById('calibrate')}>
                            <Calibrate />
                        </Route>
                        <Route path={getPathById('connectData')}>
                            <ConnectData />
                        </Route>
                        <Route exact path={getPathById('manageAccounts')}>
                            <ManageAccounts />
                        </Route>
                        <Route exact path={getPathById('documents')}>
                            <Documents />
                        </Route>
                        <Route exact path={getPathById('notifications')}>
                            <Notifications />
                        </Route>
                        <Route path={getPathById('upload')}>
                            <Upload />
                        </Route>
                        {/* Quality Tools page tabs */}
                        <Route exact path={getPathById('rewriteControl')}>
                            <DataQuality activeTab="rewriteControl" />
                        </Route>
                        <Route exact path={getPathById('checkControlQuality')}>
                            <DataQuality activeTab="checkControlQuality" />
                        </Route>

                        {/* Alternative Dashboard */}
                        {/* <Route exact path={getPathById('interimDashboard')}>
                            <Dashboard />
                        </Route> */}

                        {/* Any additional Routes that are not page related */}
                        <Route exact path={getPathById('settings')}>
                            <Settings />
                        </Route>
                    </Switch>
                </React.Suspense>
            </div>
        )}
    />
);
