import React from 'react';
import { useThemeProvider, useToaster, CommonProp, generate } from '@acin/ui-core';
import * as SC from './UserPreferencesModal.styled';
import { Thumbnail } from './Thumbnail';
import { ISetUiThemeMutation, useSetUiThemeMutation } from '../graphql/mutations/setUiTheme.generated';
import { useMeQuery } from '../../MainNav/graphql/queries/me.generated';
import { IUiTheme } from '../../../graphql/generated';

export interface UserPreferencesModalProps extends CommonProp {
    className?: string;
    onClose: () => void;
    show: boolean;
}

export const UserPreferencesModal: React.FC<UserPreferencesModalProps> = (props) => {
    const { className, show, onClose } = props;
    const { onAddToast } = useToaster();
    const { data } = useMeQuery();

    const [setUiTheme] = useSetUiThemeMutation({
        onCompleted: (data: ISetUiThemeMutation) => {
            onAddToast({
                id: `theme-updated`,
                title: 'Appearance preference saved',
                status: 'success',
                message: `You're now using the ${themeSelection} theme`,
                removeAt: +new Date() + 10000,
            });
        },
    });

    const { setTheme, activeTheme } = useThemeProvider();

    const [themeSelection, setThemeSelection] = React.useState<string>(activeTheme.name);

    React.useEffect(() => {
        setThemeSelection(activeTheme.name);
    }, [setThemeSelection, activeTheme]);

    const handleSetTheme = () => {
        if (data?.me.id) {
            setUiTheme({
                variables: {
                    userId: data?.me?.id!,
                    theme: themeSelection === 'dark' ? IUiTheme.Dark : IUiTheme.Default,
                },
            });
        }
        setTheme(activeTheme);
        onClose();
    };

    const handleClose = () => {
        onClose();
        setThemeSelection(activeTheme.name);
    };

    return (
        <SC.UserPreferencesModal
            data-testid={generate(['user-preferences-modal'], props)}
            className={className}
            title="Edit Preferences"
            show={show}
            onClose={handleClose}
            footerButtonPrimary={{
                label: 'Update Preferences',
                disabled: activeTheme.name === themeSelection,
                onClick: handleSetTheme,
            }}
        >
            <SC.Title weight={500} size={0}>
                Appearance
            </SC.Title>
            <SC.ThumbnailWrapper>
                <Thumbnail
                    variant="default"
                    onClick={() => setThemeSelection('default')}
                    isActive={themeSelection === 'default'}
                    title="Light Mode"
                />
                <Thumbnail
                    variant="dark"
                    onClick={() => setThemeSelection('dark')}
                    isActive={themeSelection === 'dark'}
                    title="Dark Mode (Beta)"
                />
            </SC.ThumbnailWrapper>
        </SC.UserPreferencesModal>
    );
};
