import { USER_ROLES } from '@acin/user-accounts';
import { MainNavMenuItem } from '@acin/ui-core';
import { SecondLevelNavItem } from '@acin/ui-core/types/src/components/MainNavHorizontal/components/MainNavHorizontal';

export interface AppMenuItem extends MainNavMenuItem {
    permissions?: string[];
}

export const menu = (reportCategories?: SecondLevelNavItem[]): AppMenuItem[] => {
    const buildReportCategoryLinks = () => {
        if (reportCategories) {
            return reportCategories?.map((item) => ({
                label: item.label,
                id: `/calibrate/quick-filter/${item.id}`,
            }));
        }

        return [];
    };

    return [
        {
            id: 'controlQualityTools',
            permissions: [
                USER_ROLES.SUPER_ADMIN,
                USER_ROLES.ACIN_QUALITY_TOOLS_USER,
                USER_ROLES.CUSTOMER_QUALITY_TOOLS_USER,
            ],
            label: 'Control Quality Tools',
            secondLevelNavigation: {
                navItems: [
                    {
                        label: 'Rewrite Control',
                        id: 'rewriteControl',
                    },
                    {
                        label: 'Check Control Quality',
                        id: 'checkControlQuality',
                    },
                ],
            },
        },
        {
            id: 'calibrate',
            permissions: [USER_ROLES.ACIN_USER, USER_ROLES.CUSTOMER_USER, USER_ROLES.DATA_USER, USER_ROLES.SUPER_ADMIN],
            label: 'Insight Reports',
            secondLevelNavigation: {
                navItems: [...buildReportCategoryLinks()],
                highlights: [
                    {
                        label: 'All Reports',
                        id: 'calibrate',
                    },
                    {
                        label: 'Create Report',
                        id: 'reportBuilder',
                    },
                ],
            },
        },
        {
            id: 'riskIntelligenceHub',
            permissions: [
                USER_ROLES.ACIN_USER,
                USER_ROLES.CUSTOMER_USER,
                USER_ROLES.DATA_USER,
                USER_ROLES.SUPER_ADMIN,
                USER_ROLES.RISK_INTEL_USER,
            ],
            label: 'Risk Intelligence',
        },
        {
            id: 'connectData',
            permissions: [USER_ROLES.SUPER_ADMIN, USER_ROLES.DATA_USER],
            label: 'Connect Data',
        },
        {
            id: 'manageAccounts',
            label: 'Manage Accounts',
            permissions: [USER_ROLES.SUPER_ADMIN],
        },
    ];
};

export const getMenuItems = (permissions?: string[], reportCategories?: SecondLevelNavItem[]): MainNavMenuItem[] => {
    if (!permissions) return [];

    const items = menu(reportCategories).filter((item) =>
        item.permissions?.some((itemPermissions) => permissions.includes(itemPermissions)),
    );

    return items.map((item) => ({ id: item.id, label: item.label, secondLevelNavigation: item.secondLevelNavigation }));
};

const routingMap = new Map([
    ['dashboard', '/'],
    ['interimDashboard', '/interim-dashboard'],
    ['search', '/search'],
    ['riskIntelligence', '/risk-intelligence'],
    ['riskIntelligenceAdmin', '/risk-intelligence/admin'],
    ['riskIntelligenceHub', '/risk-intelligence/hub'],
    ['explorer', '/explorer'],
    ['calibrate', '/calibrate'],
    ['connectData', '/data-management/maintain-data'],
    ['manageAccounts', '/data-management/manage-accounts'],
    ['documents', '/documents'],
    ['notifications', '/notifications'],
    ['settings', '/settings'],
    ['upload', '/upload'],

    // Data quality page
    ['checkControlQuality', '/quality-tools/check-control-quality'],
    ['rewriteControl', '/quality-tools/rewrite-control'],

    // Calibrate
    ['reportBuilder', '/calibrate/report-builder'],
]);

// Reverse map for looking up the id from the path
export const reverseRoutingMap = new Map([...routingMap].map((item) => [item[1], item[0]]));

export const getPathById = (id: string) => routingMap.get(id);
export const getIdByPath = (path: string) => reverseRoutingMap.get(path);
